const objectFitImages = require('object-fit-images');
const Flickity = require('flickity');
require ('flickity-imagesloaded');
require('colcade');
import Splitting from 'splitting';
import ScrollOut from 'scroll-out';

(function($) {

	const dig = {};

	dig.hamburgerMenu = () => {
		$('.hamburger').on('click', function(){
			$('body').toggleClass('menu-active');

			$(this).toggleClass('is-active');
			$('.nav-mobile__inner').toggleClass('open');
		});
	}

	dig.splitTextAnimation = () => {
		Splitting();

		ScrollOut({
			targets: '.word',
			scrollingElement: '.container',
		})
	}

	dig.logoCarousel = () => {
		const checkLogos = document.getElementById("brand-carousel");
		if (checkLogos) {
			const flkty = new Flickity( '#brand-carousel', {
				contain: true,
				pageDots: false,
				wrapAround: true,
				freeScroll: true,
				prevNextButtons: false,
				autoPlay: 3000,
				groupCells: true,
				imagesLoaded: true,
				cellAlign: 'left',
			});
		}
	}

	dig.init = function() {
		dig.hamburgerMenu();
		dig.logoCarousel();
		dig.splitTextAnimation();
	}

	dig.init();

	$(document).ready(function(){
		$('body').trigger('bless');
		objectFitImages();
    });

})(jQuery);
